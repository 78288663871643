import './utils/vh.utils.js';
import './utils/animate-on-view.utils';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/meta-select-component.js';
import './components/header.component.js';
import './components/cards-orders.component.js';
import './components/shop-cart.component.js';
import './components/order-page.component.js';
import './components/player.component.js';

/*swipers*/
import './components/swiper-hero-banner.component.js';
import './components/swiper-blogs.component.js';
import './components/swiper-hero-product.component.js';
import './components/swiper-product-info.component.js';
import './components/swiper-goods.component.js';
import './components/swiper-locations.component.js';
import './components/swiper-article.component.js';
import './components/swiper-team.component.js';
import './components/swiper-footer.component.js';
import './components/swiper-hero-about.component.js';
import './components/swiper-videos.component.js';

import './libs/countUp.lib.js';

let userAgent = navigator.userAgent.toLowerCase();

let montereyOS =
  /version\/15.6/gi.test(userAgent) && /mac os/gi.test(userAgent);
let iphone = /iphone/.test(navigator.userAgent.toLowerCase());
let ipad = /ipad/.test(navigator.userAgent.toLowerCase());

if (montereyOS) {
  document.querySelector('body').classList.add('safari-15-support');
}
if (iphone) {
  document.querySelector('body').classList.add('iphone-support');
}
if (ipad) {
  document.querySelector('body').classList.add('ipad-support');
}

let insightsFiltersToggle = $('.insights-form-categories h3');
if (insightsFiltersToggle.length) {
  insightsFiltersToggle.click(function () {
    $(this).toggleClass('active');
    $(this)
      .next('.insights-form-categories-list')
      .stop(true, true)
      .slideToggle();
  });
}

const footerNavbarMenu = document.querySelector('.footer-navbar-menu.category');
if (footerNavbarMenu) {
  const list = footerNavbarMenu.querySelector('ul');
  const items = list.querySelectorAll('ul li');

  if (items.length < 4) {
    footerNavbarMenu.classList.add('one-column');
  } else if (items.length < 7) {
    footerNavbarMenu.classList.add('two-column');
  }
}

if (document.getElementsByClassName('aura-card') && window.innerWidth > 1025) {
  const auraCards = document.querySelectorAll('.aura-card');
  const auraCardsCursor = document.querySelectorAll('.aura-card .cursor-aura');
  let isUpdating = false; // Флаг для предотвращения излишних обновлений
  let isMouseDown = false; // Флаг для отслеживания состояния кнопки мыши

  // Функция для обновления положения родителя
  const updateCardPosition = (card) => {
    return card.getBoundingClientRect();
  };

  // Функция для позиционирования курсора
  const updateCursorPosition = (e, card, i) => {
    if (!isUpdating) {
      isUpdating = true; // Фиксируем флаг, чтобы не обновлять много раз за один момент времени
      requestAnimationFrame(() => {
        const rect = updateCardPosition(card); // Обновляем положение родителя
        let x = e.clientX - rect.left; // Вычисляем смещение по X относительно родителя
        let y = e.clientY - rect.top; // Вычисляем смещение по Y относительно родителя
        auraCardsCursor[i].style.transform = `translate(${x}px, ${y}px)`; // Позиционируем ауру
        isUpdating = false; // Снимаем блокировку
      });
    }
  };

  auraCards.forEach((card, i) => {
    // Обновляем координаты родителя через 2 секунды после загрузки страницы
    window.addEventListener('load', () => {
      setTimeout(() => {
        const rect = updateCardPosition(card); // Позиция родителя
        // Позиционируем ауру сразу при загрузке с задержкой
        auraCardsCursor[i].style.transform = `translate(${rect.width / 2}px, ${
          rect.height / 2
        }px)`;
      }, 2000); // Задержка в 2 секунды после загрузки
    });

    card.addEventListener('mousemove', (e) => {
      updateCursorPosition(e, card, i); // Обновляем положение ауры при движении мыши
    });

    card.addEventListener('mouseenter', () => {
      auraCardsCursor[i].classList.add('active');
      card.classList.add('active');
    });

    card.addEventListener('mouseleave', () => {
      // Если курсор покидает карту, аура перемещается в центр
      if (!isMouseDown) {
        const rect = updateCardPosition(card); // Получаем координаты карты
        auraCardsCursor[i].style.transform = `translate(${rect.width / 2}px, ${
          rect.height / 2
        }px)`; // Перемещаем ауру в центр
        auraCardsCursor[i].style.opacity = 1; // Восстанавливаем прозрачность
      }
      auraCardsCursor[i].classList.remove('active');
      card.classList.remove('active');
    });

    // Когда зажата кнопка мыши
    card.addEventListener('mousedown', () => {
      isMouseDown = true; // Устанавливаем флаг на "зажата"
      auraCardsCursor[i].style.opacity = 0; // Прозрачность ауры становится 0
    });

    // Когда отпущена кнопка мыши
    card.addEventListener('mouseup', () => {
      isMouseDown = false; // Сбрасываем флаг
      auraCardsCursor[i].style.opacity = 1; // Восстанавливаем прозрачность
    });
  });
}
