import Swiper, {
  Autoplay,
  Navigation,
  Controller,
  EffectFade,
  EffectCreative,
} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let heroProductSwiperWr = document.querySelectorAll('.hero-product-swiper-wr');
heroProductSwiperWr.forEach((el) => {
  if (el) {
    let swiperThumbsEl = el.querySelector('.swiper-thumbs');
    let swiperMainEl = el.querySelector('.swiper-main');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = swiperThumbsEl.querySelectorAll('.swiper-slide').length;
    let initialSlide = slidesCount > 1 ? 1 : 0;
    const btn360 = document.querySelector('.hero-product-360-btn');
    const slide360 = document.querySelector('.product-thumb-item-360');

    let thumbSwiper = new Swiper(swiperThumbsEl, {
      modules: [Autoplay, Navigation, Controller, EffectCreative],
      initialSlide: initialSlide,
      speed: 800,
      threshold: 10,
      slidesPerView: 1,
      slideToClickedSlide: true,
      centeredSlides: true,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      effect: 'creative',
      creativeEffect: {
        prev: {
          translate: [0, '-100%', 0],
          scale: 0.75,
        },
        next: {
          translate: [0, '100%', 0],
          scale: 0.75,
        },
      },
      breakpoints: {
        751: {
          direction: 'vertical',
          slidesPerView: 1,
          creativeEffect: {
            prev: {
              translate: [0, '-100%', 0],
              scale: 0.75,
            },
            next: {
              translate: [0, '100%', 0],
              scale: 0.75,
            },
          },
        },
        1100: {
          creativeEffect: {
            prev: {
              translate: [0, 'calc(-100% + 12px)', 0],
              scale: 0.75,
            },
            next: {
              translate: [0, 'calc(100% - 12px)', 0],
              scale: 0.75,
            },
          },
        },
      },

      /*  autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
        },*/
    });

    let mainSwiper = new Swiper(swiperMainEl, {
      modules: [EffectFade, Controller],
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      effect: 'fade',
      initialSlide: initialSlide,
      autoHeight: true,
      fadeEffect: {
        crossFade: true,
      },
      allowTouchMove: false,
      breakpoints: {
        751: {
          autoHeight: false,
        },
      },
    });

    swiperObserver(thumbSwiper);

    thumbSwiper.controller.control = mainSwiper;
    mainSwiper.controller.control = thumbSwiper;

    if (btn360 && slide360) {
      btn360.addEventListener('click', function () {
        const slide360Parent = slide360.parentElement;
        if (slide360Parent) {
          const targetIndex = Array.from(
            swiperMainEl.querySelectorAll('.swiper-slide')
          ).indexOf(slide360Parent);
          if (targetIndex !== -1) {
            mainSwiper.slideTo(targetIndex);
          }
        }
      });
    }
  }
});
