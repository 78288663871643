import Swiper, { Autoplay, Navigation, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let heroAboutSwiperWr = document.querySelectorAll('.hero-about-swiper-wr');
heroAboutSwiperWr.forEach((el) => {
  if (el) {
    console.log(el);
    
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentNode;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectFade],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      speed: 1100,
      threshold: 10,
      allowTouchMove: true,
      effect: 'fade',
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      // effect: 'creative',
      // creativeEffect:{
      //   prev:{
      //     translate: [-20, 0, 0],
      //     scale: 0.95
      //   },
      //   next:{
      //     translate: [20, 0, 0],
      //     scale: 0.95
      //   }
      // }
    });
  }
});


